import React from 'react'
import 'aos/dist/aos.css'
import '../Styling/Services.css'
import IMGCARD from './IMGCARD'

/* this array holds all the info I need to create the image cards of all the services genesis offers */
const serviceList = [
  {image: "/images/serviceImages/drywall.jpg", title: "Drywall", service: "Drywall & Plastering"},
  {image: "/images/serviceImages/painting.jpg", title: "Painting", service: "Interior & Exterior Painting"},
  {image: "/images/serviceImages/flooring.png", title:"Flooring", service: "Flooring"},
  {image: "/images/serviceImages/kitchen.jpg", title: "Kitchens", service: "Kitchens"},
  {image: "/images/serviceImages/bathroom.jpg", title:"Bathrooms", service: "Bathrooms"},
  {image: "/images/serviceImages/windows.jpg", title:"Windows&Doors", service: "Windows & Doors"},
  {image: "/images/serviceImages/roofing.png", title:"Roofing,Gutters,&Downspouts", service: "Roofing, Gutters, & DownSpouts"},
  {image: "/images/serviceImages/Decks.jpg", title:"Decks&Fences", service:"Decks & Fences"},
  {image: "/images/serviceImages/stonework.jpg", title:"Stonework&Concrete", service:"Stonework & Concrete"},
  {image: "/images/serviceImages/otherServices.jpg", title:"OtherServices", service:"Other Services"}
];

class Services extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div id="service-component-container">
                {/* this div is just a spacer for the smoothscrolling to land in the correct spot*/}
                <div id="services" class="hidden-spacer"/>
                {/* this is where our service component actually begins*/}
                <div id="services-container" class="my-col">
                    <h1 data-aos="slide-right" data-aos-duration="1000">Our Services</h1>
                    <div id="service-card-container" class="my-row">
                        {/* generates all the service image cards */}
                        {serviceList.map(function(value){
                            return(
                                <IMGCARD className="imgCard" imgPath={value.image} imgCaption={value.service} urlEndpoint={value.title} />
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
}


export default Services;
