import '../Styling/ContactUs.css'
import React from 'react';

class ContactUs extends React.Component{
    constructor(props)
    {
        super(props);
    }

    render(){
        return(
            <div id="contact-us-container" class="my-col">

                <div id="phone-email-address-container" class="my-col">
                    
                    <h3>Contact Us</h3>
                
                    <div class="contact-row my-row">
                        <i class="fas fa-phone-alt" aria-hidden="true"></i>
                        <a href={`tel: ${this.props.phoneNumber}`} > {this.props.phoneNumber} </a>
                    </div>
                
                    <div class="contact-row my-row">
                        <i class="far fa-envelope" aria-hidden="true"></i>
                        <a href={`mailto: ${this.props.email}`}> {this.props.email} </a>
                    </div>

                    <div class="contact-row my-row">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <a href= {`http://maps.google.com/?q= ${this.props.address}`} >{this.props.address}</a>
                    </div>

                </div>

                <div id="hours-container" className="my-col">
                    
                    <h3>Hours</h3>

                    <div class="contact-row my-row">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        <p>Monday-Friday: 7:00 a.m. - 5:00 p.m.</p>
                    </div>

                    <div class="contact-row my-row">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        <p>Saturday: 10:00 a.m. - 2:00 p.m.</p>
                    </div>

                    <div class="contact-row my-row">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        <p>Sunday: Closed</p>
                    </div>

                </div>  

            </div>
        );
    }
}

export default ContactUs;