import '../Styling/Form.css';
import React from 'react';
import emailjs from 'emailjs-com'

class Form extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = {
            fName: "",
            lName: "",
            email: "",
            phone: "",
            message: "",
            messageSent: false,
            display: "none"

        };
        this.fNameHandler = this.fNameHandler.bind(this);
        this.lNameHandler = this.lNameHandler.bind(this);
        this.emailHandler = this.emailHandler.bind(this);
        this.phoneHandler = this.phoneHandler.bind(this);
        this.messageHandler = this.messageHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.sentMessageText = this.sentMessageText.bind(this);
    }

    /* FUNCTIONS UPDATE TEXT IN INPUT & TEXT AREA BOXES */
    fNameHandler(e){
        e.preventDefault();
        this.setState({
            fName: e.target.value
        });
    }

    lNameHandler(e){
        e.preventDefault();
        this.setState({
            lName: e.target.value
        });
    }

    emailHandler(e){
        e.preventDefault();
        this.setState({
            email: e.target.value
        });
    }

    phoneHandler(e){
        e.preventDefault();
        this.setState({
            phone: e.target.value
        });
    }

    messageHandler(e){
        e.preventDefault();
        this.setState({
            message: e.target.value
        });
    }
    /* END */

    //Sends form data as an email to genesis email
    submitHandler(e){
        e.preventDefault();
        this.setState({fName: '', lName: '', email: '', phone: '', message: '', messageSent: !this.state.messageSent, display: "inline"});
        setTimeout(this.sentMessageText, 5000);
        
        emailjs.sendForm('genesis_email', 'template_kdlp0jx', e.target, 'user_8Idk8uCgo0zanXMomWsOI')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          
    }

    //displays that the message was sent
    sentMessageText(){
        this.setState({
            messageSent: !this.state.messageSent
        });
        setTimeout(()=>{this.setState({display: "none"})}, 600);
    }

    render(){
        return(
            <div class="form-container" class="my-col">
                <form onSubmit={(event) => this.submitHandler(event)} id={`basic-form-${this.props.formTag}`} class="basic-form my-col">
                    
                    {/* FIRST & LAST NAME ROW */}
                    <div class="form-row my-row">

                        {/* FIRST NAME */}
                        <div class="form-col my-col">
                            <label for="first-name" form={`basic-form-${this.props.formTag}`}>First Name:</label>
                            <input required id="first-name" class="form-control" name="first-name" form={`basic-form-${this.props.formTag}`}
                                type="text" value={this.state.fName} onChange={this.fNameHandler}>
                            </input>
                        </div>
                        
                        {/* LAST NAME */}
                        <div class="form-col my-col">
                            <label for="last-name" form={`basic-form-${this.props.formTag}`}>Last Name:</label>
                            <input required id="last-name" class="form-control" name="last-name" form={`basic-form-${this.props.formTag}`}
                                type="text" value={this.state.lName} onChange={this.lNameHandler}>
                            </input>
                        </div>

                    </div>

                    {/* EMAIL & PHONE ROW */}
                    <div class="form-row my-row">

                        {/* EMAIL */}
                        <div class="form-col my-col">
                            <label for="email" form={`basic-form-${this.props.formTag}`}>Email:</label>
                            <input required id="email" class="form-control" name="email" form={`basic-form-${this.props.formTag}`}
                                type="email" value={this.state.email} onChange={this.emailHandler}>
                            </input>
                        </div>

                        {/* PHONE NUMBER */}
                        <div class="form-col my-col">
                            <label for="phone" form={`basic-form-${this.props.formTag}`}>Phone Number:</label>
                            <input required minlength="10" id="phone" class="form-control" name="phone-number" form={`basic-form-${this.props.formTag}`}
                                type="tel" value={this.state.phone} onChange={this.phoneHandler}>
                            </input> 
                        </div>

                    </div>
                    
                    {/* MESSAGE */}
                    <label for="message" form={`basic-form-${this.props.formTag}`}>Message:</label>
                    <textarea required id="message" class="form-control" name="message" form={`basic-form-${this.props.formTag}`}
                        value={this.state.message} onChange={this.messageHandler}>
                    </textarea>

                    {/* SUBMIT BUTTON */}
                    <button type="submit" id="form-button" class="btn btn-default"><i class="fa fa-paper-plane" aria-hidden="true"></i> Send Message </button>

                    {/* MESSAGE SENT TEXT*/}
                    <i id="sent-message" class={(this.state.messageSent) ? "Show" : "Hide"} style={{display: `${this.state.display}`}}>Message Sent!</i>
                </form>

            </div>
        );
    };
}

export default Form;