import React from 'react'
import '../Styling/Carousel.css'

export default class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        };
    }

    render() {
        return (
            <div className="carousel-container">
                <div id="hero-carousel" class="carousel slide" data-ride="carousel" data-interval="3500">
                    
                    {/* Container for the slids */}
                    <div class="carousel-inner">

                        {/* First Slide / Active Slide */}
                        <div class="carousel-item active">
                            <img class="d-block w-100" src={this.props.sliderArray[0].imageUrl} alt="First slide"/>
                            <div id="carousel-cap1" class="carousel-caption">
                                <div class="caption-text-container">
                                    <a href="#contact-us" class="carousel-caption-link">
                                        <h1>{this.props.sliderArray[0].imageHeader}</h1>
                                        <div></div>
                                        <p>{this.props.sliderArray[0].imageText}</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Second Slide */}
                        <div class="carousel-item">
                            <img class="d-block w-100" src={this.props.sliderArray[1].imageUrl} alt="Second slide"/>
                            <div id="carousel-cap2" class="carousel-caption">
                                <div class="caption-text-container">
                                    <a href="#contact-us" class="carousel-caption-link">
                                        <h1>{this.props.sliderArray[1].imageHeader}</h1>
                                        <div></div>
                                        <p>{this.props.sliderArray[1].imageText}</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Third Slide */}
                        <div class="carousel-item">
                            <img class="d-block w-100" src={this.props.sliderArray[2].imageUrl} alt="Third slide"/>
                            <div id="carousel-cap3" class="carousel-caption">
                                <div class="caption-text-container">
                                    <a href="#contact-us" class="carousel-caption-link">
                                        <h1>{this.props.sliderArray[2].imageHeader}</h1>
                                        <div></div>
                                        <p>{this.props.sliderArray[2].imageText}</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div> {/* END OF SLIDES CONTAINER */}

                    {/* ARROWS */}
                    <a class="carousel-control-prev" href="#hero-carousel" role="button" data-slide="prev">
                        <i id="carousel-prev-arrow" class="fas fa-angle-left"></i>
                    </a>
                    <a class="carousel-control-next" href="#hero-carousel" role="button" data-slide="next">
                        <i id="carousel-next-arrow" class="fas fa-angle-right"></i>
                    </a>

                </div> {/* End of Carousel Container */}
            </div> 
        );
    }
}