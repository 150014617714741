import React from 'react';
import 'aos/dist/aos.css';
import '../Styling/Call2Action1.css';

function Call2Action1() {
    return (
        <div id="c2a-container">
            <div id="c2a-img-container">
                <a data-aos="zoom-in" data-aos-duration="800" data-aos-delay="150" href="#contact-us">
                    <h1 id="c2a-text">SCHEDULE YOUR NEXT PROJECT TODAY!</h1>
                </a>
            </div>
        </div>
    )
}

export default Call2Action1
