import '../Styling/PhotoGallery.css'
import React from 'react'

export default class PhotoGallery extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            currentIndex: 0,
        };
        this.handleRightClick = this.handleRightClick.bind(this);
        this.handleLeftClick = this.handleLeftClick.bind(this);
    }
    /* this function transitions the carousel to the next image in the gallery */
    handleRightClick(index)
    {
        index++;
        /* if we are at the last image in the list and the user tries to view the next image we loop back to the beginning of the list */ 
        if(index >= this.props.gallery.length)
        {
            index = 0; 
        }
        this.setState({currentIndex: index});

    }
    /* this function transitions the carousel to the previous image in the gallery */
    handleLeftClick(index)
    {
        index--;
        /* if we are at the beginning of the list and the user tries to view the previous image we loop to the end of the image list */
        if(index < 0)
        {
            index = this.props.gallery.length -1;
        }
        this.setState({currentIndex: index});
    }
    render(){
        return(
            <div id="photo-gallery-container my-col">
                {/* Contains Carousel Image */}
                <div id="my-carousel"
                    style=
                        {{
                            /* IMAGE STYLING FOR CAROUSEL */
                            'background-image': `url(${this.props.gallery[this.state.currentIndex]})`,
                            width: '100%', 
                            height: '80vh', 
                            'background-position': 'center',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat',
                            'background-color':'grey'
                        }}
                >

                    {/* Carousel's left arrow */}
                    <div id="carousel-arrow-container" onClick={() => this.handleLeftClick(this.state.currentIndex) }>
                        <a>
                            <i class="fas fa-angle-left"></i>
                        </a>
                    </div>
                    
                    {/* Carousel Image Number */}
                    <div id="image-position-number">
                        <h6>{`${this.state.currentIndex + 1} / ${this.props.gallery.length}`}</h6>
                    </div>

                    {/* Carousel's right arrow */}
                    <div id="carousel-arrow-container" onClick={() => this.handleRightClick(this.state.currentIndex)}>
                        <a>
                            <i class="fas fa-angle-right"></i>
                        </a>
                    </div>

                </div>
            </div>
        );
    }
}