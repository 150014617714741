import React, { Component } from 'react'
import ContactBanner from '../Components/ContactBanner'
import Navbar from '../Components/Navbar'
import Carousel from '../Components/Carousel'
import About from '../Components/About'
import Call2Action1 from '../Components/Call2Action1'
import Services from '../Components/Services'
import Contact from '../Components/Contact'
import Testimonials from '../Components/Testimonials'
import Footer from '../Components/Footer'
import '../Styling/Home.css'

// this array contains all the info needed for the carousel 
const sliderContent = [
    {imageUrl: "../images/carouselHeroImages/homeRemodeling_1440x600.jpg", imageHeader: 'GENESIS HOME REMODELING', imageText: 'PROVIDING QUALITY SERVICE TO THE D.M.V. AREA SINCE 1989'},
    {imageUrl: "../images/carouselHeroImages/beigeKitchen_1440x600.jpg", imageHeader: 'GENESIS HOME REMODELING', imageText: "SOLUTIONS DESIGNED TO FIT YOUR BUDGET AND LIFESTYLE"},
    {imageUrl: "../images/carouselHeroImages/whiteBathroom_1440x600.jpg", imageHeader: 'GENESIS HOME REMODELING', imageText: "START YOUR PROJECT TODAY!" }
];

export default class Home extends Component{
    constructor(props)
    {
        super(props);
        this.state = {
        };
    }

    render(){
        return(
        <div className="HomePage">
            <Navbar/>
            <ContactBanner/>
            <div className="header-spacer"/>
            <Carousel sliderArray={sliderContent}/>
            <About/>
            <Call2Action1/>
            <Services/>
            <Contact/>
            <Testimonials/>
            <Footer/>
        </div>
        );
    }
}