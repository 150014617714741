import React, { Component, useEffect } from 'react'
import '../Styling/Contact.css'
import 'aos/dist/aos.css'
import ContactUs from '../Components/ContactUs'
import Form from '../Components/Form'

export default class Contact extends Component 
{
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div id="contact-outer-container" class="my-col">
                <div id="contact-us" class="hidden-spacer"/>
                
                {/** contact content layout container*/}
                <div id="contact-container" class="my-row">
                    
                    {/* form container */}
                    <div data-aos="slide-right" data-aos-duration="1000" id="form-wrapper" className="my-col">
                        <h2>Leave Us A Message</h2>
                        <Form formTag="contact"/>
                    </div>

                    {/* contact info container */}
                    <div data-aos="slide-left" data-aos-duration="1000" id="contact-us-wrapper" className="my-col">
                        <ContactUs phoneNumber="(703) 220-3487" email="GenesisHomeRemodeling.info@gmail.com" address="Burke, VA 22015"/>
                    </div>

                </div>
            </div>
        )
    }
}

