import React from 'react'
import '../Styling/IMGCARD.css'
import {withRouter} from 'react-router-dom'
import 'aos/dist/aos.css'

class IMGCARD extends React.Component 
{
    constructor(props) {
        super(props);
        this.nextPath = this.nextPath.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    // when a user clicks on the image card they're sent to the correct service page path
    handleClick() {
        this.nextPath(`/service-page/${this.props.urlEndpoint}`);
    }

    //redirects user to the proper page using react router 
    nextPath(path){
        this.props.history.push(path);
    }

    render() {
        
        //sets the correct styling for the background image
        const divStyle = {
            backgroundImage : 'url(' + this.props.imgPath + ')' 
        };
        return (
        <div onClick={this.handleClick} data-aos="zoom-in" data-aos-duration="1000" class={"img-card-container my-col"} style={divStyle} >
            <button onClick={this.handleClick} type="button" class="btn btn-default" id="service-btn">
                {this.props.imgCaption}
            </button>
        </div>
        )
    }
}
export default withRouter(IMGCARD);

