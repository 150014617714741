import React, { Component } from 'react'
import '../Styling/Footer.css'
import ContactUs from "../Components/ContactUs.js"
import Form from '../Components/Form'
import "aos/dist/aos.css";

export default class Footer extends Component 
{
    constructor(props) {
        super(props)
    
        this.state = {

        }
    }

    render() {
        return (
            <div id="footer-component-container" class="my-col">

                {/* contains all footer boxes */}
                <div id="footer-container" class="my-row">
                    
                    {/* Company logo and message container */}
                    <div data-aos="slide-right" data-aos-duration="1000" data-aos-delay="200" id="fbox1" class="footer-box my-col">
                        
                        <div id="footer-logo-container" class="my-row">
                            <div id="footer-logo"/>
                        </div>
                        
                        <div id="footer-company-text-container" class="my-row">
                            <h5>
                                Our vision is to provide quality work, incredible performance, and competitive prices.
                                We enjoy our work and take pride in our long-standing history of satisfying clients
                                throughout the Washington DC Metropolitan area.
                            </h5>
                        </div>

                    </div>
                    
                    {/* Form container */}
                    <div data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200" id="fbox2" class="footer-box my-col">
                        <div id="form-outer-container" class="my-col">
                            
                            <h3>Contact Us Today</h3>
                            
                            <div id="footer-form-container" class="my-col">
                                <Form formTag="footer"/>
                            </div>

                        </div>
                    </div>
                    
                    {/* Contact us container */}
                    <div data-aos="slide-left" data-aos-duration="1000" data-aos-delay="200" id="fbox3" class="footer-box my-col">
                        <ContactUs phoneNumber="(703) 220-3487" email="GenesisHomeRemodeling.info@gmail.com" address="Burke, VA 22015"/>
                    </div>

                </div>{/* End of footer container */}

                <div id="copyright-container"class="my-row">
                    <p>1989-{new Date().getFullYear()} &copy; Genesis Home Remodeling-Virginia. All Right Reserved.</p>
                </div>

            </div>
        )
    }
}
