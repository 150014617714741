import React, { Component } from 'react'
import '../Styling/Navbar.css'
import {Link, withRouter} from 'react-router-dom'


class Navbar extends Component {

    constructor(props)
    {
        super(props);
    }

    render() {
        return (
                <div class="navbar-container my-row">
                    <nav id="genesis-nav" class="my-row">

                        {/* LOGO */}
                        <div class="logo-div-container my-row">
                            <a class="logo-link" href="/#" >
                                <div class="logo-img"/>
                            </a>
                        </div>

                        {/* LINKS */}
                        <ul class= "nav-links my-row">
                            <li class="my-col"><a id="h" class="my-row" href="/#">Home</a></li>
                            <li class="my-col"><a id="a" class="my-row" href="/#about-us">About</a></li>
                            <li class="my-col"><a id="s" class="my-row" href="/#services">Services</a></li>
                            <li class="my-col"><Link id="pg" class="my-row" to="/project-gallery">Gallery</Link></li>
                            <li class="my-col"><a id="c" class="my-row" href="/#contact-us">Contact</a></li>
                        </ul>

                    </nav>
                </div>
        )
    }
}

export default withRouter(Navbar);