import React from 'react'
import '../Styling/ContactBanner.css'

function ContactBanner() {
    return (
        <div id="contact-banner" class="my-row">

            <a class="contact-link my-row" href="tel: (703) 220-3487">
                <p>Call Us Now!</p> 
            </a>

            <a class="contact-link my-row" href="tel: (703) 220-3487">
                <i id="contact-phone-icon" class="fas fa-phone-alt"/>
                <p>(703) 220-3487</p>
                <p id="contact-spacer"> | </p>
            </a>

            <a class="contact-link my-row" href="mailto: GenesisHomeRemodeling.info@gmail.com">
                <i id="contact-email-icon" class="far fa-envelope"/>
                <p>GenesisHomeRemodeling.info@gmail.com</p>
            </a>

        </div>
    )
}

export default ContactBanner
