import React, { Component } from 'react'
import './QuoteBox'
import '../Styling/Testimonials.css'
import QuoteBox from './QuoteBox';
import {CSSTransition} from 'react-transition-group';
import 'aos/dist/aos.css'

/* this array holds all the information needed for the testimonial carousel */
const quotes = [
    {quote: `I just want to thank you for a fantastic job. I used the new bathroom this morning for the first
time - I'm in heaven!!!`, author: `Leslie`, location: `Baltimore, Maryland`},
    {quote: `I just wanted to thank you for all of your help and flexibility in repairing my rental properties
and also for your patience in working with my aunt. She is really pleased with the work that
you and your team have done to her building.`, author: `Heather`, location: `Alexandria, Virginia`}, 
    {quote: ` I just wanted to say that I am impressed with the quality of your work and very pleased with
the results. You will be highly recommended!`, author: `Johanna`, location: `Woodbridge, Virginia`}, 
    {quote: ` The work was completed in just under two weeks time, and the quality of the workmanship
was excellent. Ivan designed and built beautiful new columns that significantly enhance the
curb appeal of my house. His crew was careful, punctual, polite, cheerful and considerate.
At the close of business each day they left the work area clean and tidy. I am extremely
pleased with the outcome of all the work they did and would highly recommend the firm's
services to anyone looking for quality work at a reasonable price.`, author: `Francine Barbe`, location: `Bethesda, Maryland`},
    {quote: ` I Just wanted you to know that we really appreciate the excellent job that you and your crew
did for us.I was really impressed with the great attention to detail and making sure that everything was
done just right. I appreciate too your efforts to keep us updated about the timing and status
of the projects. And your pricing was very fair. We are very pleased and will call you again when other projects need to be done.
Thanks very much and best wishes for a wonderful holiday season.`, author: `Larry Miller`, location: `Arlington, Virginia`},
    {quote: `Your expertise in design renovation and residential improvements is outstanding and has been
an extremely valuable resource for me as a homeowner and as a rental investor. Thanks to you, I have provided much needed repairs to my rental properties including roof,
window, wall, and painting needs as well as a wonderful bathroom renovation to my current
residence. Your promptness, expert carpenter, plumping, and design skills have been greatly
appreciated by me at a reasonable cost. I have counted on you to deliver on some of the most important investments I have and have
been glad to recommend you to my close friends and colleagues who have also said that your
work is outstanding.`, author: `Patrick T. Campbell`, location: `Washington, DC`}
];

export default class Testimonials extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = {
            activeIndex: 0, //keeps track of testimonial index (used for highligting carousel dots)
            appearIn: true
        };
        this.handleLeftClick = this.handleLeftClick.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);
    }

    /* when the left arrow is clicked it will show you the previous testimonial */
    handleLeftClick(){
        var i = this.state.activeIndex - 1; 
        /* when the user wants to see the previous testimonial but we are at the start of the list we loop to the end of the list */
        if(i < 0)
        {
            this.setState({activeIndex: quotes.length - 1});
        }
        /* otherwise show the previous testimonial in the list */
        else
        {
            this.setState({activeIndex: i});
        }
    }

    /* when the right arrow is clicked it will show you the next testimonial */
    handleRightClick(){
        var i = this.state.activeIndex + 1; 
        /* When the user wants to see the next testimonial but we have reached the end of the list we loop back to the front*/
        if(i > quotes.length - 1)
        {
            this.setState({activeIndex: 0});
        }
        /* otherwise show the next available testimonial in the list */
        else
        {   
            this.setState({activeIndex: i});
        }
    }

    render() 
    {
        return (
            <div id="testimonial-container" class="my-col">
               
                <h1 data-aos="zoom-in" data-aos-duration="1000">What Our Clients Say</h1>
               
                <div id="quote-box-container" class="my-row" data-aos="zoom-in" data-aos-duration="1000" >

                    {/* testimonial carousel previous button */}
                    <button class="testimonial-button" onClick={this.handleLeftClick}>
                        <i class="fas fa-angle-left"/>
                    </button>
                    
                    {/*wrapper for animation purposes*/}
                    <CSSTransition in={this.state.appearIn} appear={true} timeout={300} classNames="fade">
                        
                        {/* this element contains all the testimonial info */}
                        <QuoteBox 
                            _id={this.state.activeIndex} 
                            quote={quotes[this.state.activeIndex].quote} 
                            author={quotes[this.state.activeIndex].author}
                            location={quotes[this.state.activeIndex].location}
                        />

                    </CSSTransition>
                    
                    {/* testimonial carousel next button */}
                    <button class="testimonial-button" onClick={this.handleRightClick}>
                            <i class="fas fa-angle-right"/>
                    </button>

                </div> {/* end of quotebox container */}

                {/* this section contains the position indicator dots for the testimonials */}
                <div id="position-dots-wrapper" class="my-row">
                    <div id="position-dots-container" class="my-row">
                        {/* creates our carousel dots */}
                        {quotes.map((value, index) => {
                            if(index > 14)
                            {
                                return;
                            }
                            return (
                            <span 
                                /* highlights the current testimonial position dot */
                                className={`${(this.state.activeIndex == index) ? "dots active-dot" : "dots"}`} 
                                id={`dot${index}`}/>
                            )
                        })}
                    </div>
                </div>

            </div>
        )
    }
}
