import '../Styling/ServicePage.css'
import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ContactBanner from '../Components/ContactBanner'
import PhotoGallery from '../Components/PhotoGallery'
import "aos/dist/aos.css";

//this array helps display the appropriate service page content and images 
const serviceList = [
    {image: "/images/serviceImages/drywall.jpg", 
    service: "DRYWALL & PLASTERING", 
    header: "Is it time to fix that drywall?",
    gallery: [
        "../images/photoGallery/drywall/drywall1.jpg", "../images/photoGallery/drywall/drywall2.jpg", "../images/photoGallery/drywall/drywall3.jpg", "../images/photoGallery/drywall/drywall4.jpg", 
        "../images/photoGallery/drywall/drywall5.jpg", "../images/photoGallery/drywall/drywall6.jpg", "../images/photoGallery/drywall/drywall7.jpg", "../images/photoGallery/drywall/drywall8.jpg", 
        "../images/photoGallery/drywall/drywall9.jpg", "../images/photoGallery/drywall/drywall10.jpg", "../images/photoGallery/drywall/drywall11.jpg", "../images/photoGallery/drywall/drywall12.jpg",
    ],
        paragraph: 
            `Drywall is the most widely used material in modern home construction. 
            It's also, unfortunately, one of the most susceptible materials to accidental damage; but  
            whether you're looking to repair a small hole in your drywall 
            or remodel an entire basement, Genesis has your back. 
            Our team of highly trained professionals are at your service and ready to tackle any sized project.`},

    {image: "/images/serviceImages/painting.jpg", 
    service: "INTERIOR & EXTERIOR PAINTING", 
    header: "Is it time to give your home a makeover?", 
    gallery: [],  
        paragraph: 
            `Are you ready to breath some new life into your home? Well look no further, Genesis Home Remodeling is at your service.
            Our expert team of painters will help assist you in setting the perfect ambiance for your home. Whether your looking to paint the exterior to add some curb appeal,
            or the interior to add some charm, Genesis has got you covered. 
            ` },

    {image: "/images/serviceImages/flooring.png", 
    service:"FLOORING", 
    header: "Is it time to update your flooring?", 
    gallery: [
        "../images/photoGallery/flooring/flooring1.jpg", "../images/photoGallery/flooring/flooring2.jpg", "../images/photoGallery/flooring/flooring3.jpg", "../images/photoGallery/flooring/flooring4.jpg", "../images/photoGallery/flooring/flooring5.jpg", 
        "../images/photoGallery/flooring/flooring6.jpg", "../images/photoGallery/flooring/flooring7.jpg", "../images/photoGallery/flooring/flooring8.jpg", "../images/photoGallery/flooring/flooring9.jpg", "../images/photoGallery/flooring/flooring10.jpg", 
        "../images/photoGallery/flooring/flooring11.jpg", "../images/photoGallery/flooring/flooring12.jpg", "../images/photoGallery/flooring/flooring13.jpg", "../images/photoGallery/flooring/flooring14.jpg", "../images/photoGallery/flooring/flooring15.jpg", 
        "../images/photoGallery/flooring/flooring16.jpg", "../images/photoGallery/flooring/flooring17.jpg", "../images/photoGallery/flooring/flooring18.jpg", "../images/photoGallery/flooring/flooring19.jpg", "../images/photoGallery/flooring/flooring20.jpg", 
        "../images/photoGallery/flooring/flooring21.jpg", "../images/photoGallery/flooring/flooring22.jpg", "../images/photoGallery/flooring/flooring23.jpg", "../images/photoGallery/flooring/flooring24.jpg", "../images/photoGallery/flooring/flooring25.jpg", 
    ], 
        paragraph: 
            `Flooring is perhaps one of the most impactful elements that adds to the look and feel of your home.
            Whether your interested in the sleek aesthetic of ceramic tiling, the warmth of beautiful hardwood or the plush comfort of high-quality carpeting,
            Genesis does it all. Our team of experienced flooring specialist are committed to ensuring your satisfaction, and to providing exceptional service at an affordable price.`},

    {image: "/images/serviceImages/kitchen.jpg", 
    service: "KITCHENS", 
    header: "Is it time to update your kitchen?",
    gallery: [
        "../images/photoGallery/kitchens/kitchenP1_1.jpg", "../images/photoGallery/kitchens/kitchenP1_2.jpg", "../images/photoGallery/kitchens/kitchenP2_1.jpg", "../images/photoGallery/kitchens/kitchenP2_2.jpg",
        "../images/photoGallery/kitchens/kitchenP3_1.jpg", "../images/photoGallery/kitchens/kitchenP3_2.jpg", "../images/photoGallery/kitchens/kitchenP4_1.jpg", "../images/photoGallery/kitchens/kitchenP4_2.jpg",
        "../images/photoGallery/kitchens/kitchenP4_3.jpg", "../images/photoGallery/kitchens/kitchenP5_1.jpg", "../images/photoGallery/kitchens/kitchenP6_1.jpg", "../images/photoGallery/kitchens/kitchenP7_1.jpg",
        "../images/photoGallery/kitchens/kitchenP7_2.jpg", "../images/photoGallery/kitchens/kitchenP7_3.jpg", "../images/photoGallery/kitchens/kitchenP8_1.jpg", "../images/photoGallery/kitchens/kitchenP8_2.jpg",
        "../images/photoGallery/kitchens/kitchenP9_1.jpg"

    ],  
        paragraph: 
            `The kitchen is by far the most important room in your entire house. 
            It's not just a place where you cook and eat. 
            It's a place where family and friends can get together and socialize. 
            It's a space we often find ourselves making big decisions in or planning for the day ahead.
            So make the right choice and contact us today to build a kitchen thats right for you.`},

    {image: "/images/serviceImages/bathroom.jpg", 
    service:"BATHROOMS", 
    header: "Is it time to update your bathroom?",
    gallery: ["../images/photoGallery/bathrooms/bathroomJob1_1.jpg","../images/photoGallery/bathrooms/bathroomJob1_2.jpg","../images/photoGallery/bathrooms/bathroomJob1_3.jpg","../images/photoGallery/bathrooms/bathroomJob1_4.jpg",
    "../images/photoGallery/bathrooms/bathroomJob2_1.jpg","../images/photoGallery/bathrooms/bathroomJob2_2.jpg","../images/photoGallery/bathrooms/bathroomJob2_3.jpg","../images/photoGallery/bathrooms/bathroomJob4_1.jpg",
    "../images/photoGallery/bathrooms/bathroomJob4_2.jpg","../images/photoGallery/bathrooms/bathroomJob5_1.jpg","../images/photoGallery/bathrooms/bathroomJob5_2.jpg","../images/photoGallery/bathrooms/bathroomJob5_3.jpg",
    "../images/photoGallery/bathrooms/bathroomJob6_1.jpg","../images/photoGallery/bathrooms/bathroomJob6_2.jpg","../images/photoGallery/bathrooms/bathroomJob6_3.jpg","../images/photoGallery/bathrooms/bathroomJob7_1.jpg",
    "../images/photoGallery/bathrooms/bathroomJob7_2.jpg","../images/photoGallery/bathrooms/bathroomJob7_3.jpg","../images/photoGallery/bathrooms/bathroomJob8_1.jpg","../images/photoGallery/bathrooms/bathroomJob8_2.jpg",
    "../images/photoGallery/bathrooms/bathroomJob9_1.jpg","../images/photoGallery/bathrooms/bathroomJob9_2.jpg","../images/photoGallery/bathrooms/bathroomJob10_1.jpg","../images/photoGallery/bathrooms/bathroomJob10_2.jpg",
    "../images/photoGallery/bathrooms/bathroomJob10_3.jpg","../images/photoGallery/bathrooms/bathroomJob10_4.jpg","../images/photoGallery/bathrooms/bathroomJob11_1.jpg","../images/photoGallery/bathrooms/bathroomJob11_2.jpg",
    "../images/photoGallery/bathrooms/bathroomJob12_1.jpg","../images/photoGallery/bathrooms/bathroomJob12_2.jpg","../images/photoGallery/bathrooms/bathroomMisc2.jpg","../images/photoGallery/bathrooms/bathroomMisc4.jpg",
    ], 
        paragraph:
            `The bathroom is by far the most utilized room in your entire house.
            It's the first room you use when you wake up, and the last one you use before going to bed; 
            therefore, it should be a room you enjoy using and spending time in.
            Whether you're looking to renovate your entire bathroom, troubleshoot plumbing issues or make minor updates,
            Genesis has got you covered.` },

    {image: "/images/serviceImages/windows.jpg", 
    service:"WINDOWS & DOORS", 
    header: "Is it time to update your windows and doors?", 
    gallery: [
        '../images/photoGallery/windows&doors/wnd1.jpg', '../images/photoGallery/windows&doors/wnd2.jpg', 
    ], 
        paragraph: 
            `Are your windows and doors past their prime?
            If so, maybe it's time you gave your home a much needed facelift. 
            Whether you're looking to add that wow factor when it comes time to sell, or looking to improve your home's security and energy efficiency, 
            Genesis is here to help. Our window and door specialists are committed to ensuring your satisfaction, and to providing exceptional service at an affordable price.
            ` },

    {image: "/images/serviceImages/roofing.png", 
    service:"ROOFING, GUTTERS, & DOWNSPOUTS", 
    header: "Is it time to finally address that leak?", 
    gallery: [
        '../images/photoGallery/roofing&gutters/roofing1.jpg', '../images/photoGallery/roofing&gutters/roofing2.jpg', '../images/photoGallery/roofing&gutters/roofing3.jpg', '../images/photoGallery/roofing&gutters/roofing4.jpg', 
        '../images/photoGallery/roofing&gutters/roofing5.jpg', '../images/photoGallery/roofing&gutters/roofing6.jpg', '../images/photoGallery/roofing&gutters/roofing7.jpg', '../images/photoGallery/roofing&gutters/roofing8.jpg', 
        '../images/photoGallery/roofing&gutters/roofing9.jpg', '../images/photoGallery/roofing&gutters/roofing10.jpg', '../images/photoGallery/roofing&gutters/roofing11.jpg', 
    ],
        paragraph: 
            `Are you experiencing a leaky roof or basement? Or, maybe having trouble with standing water in your yard?
            If so, theres no need to worry, Genesis is here to help. 
            Our experienced roofing and gutter specialist understand the best ways to address and repair all types of water leaks, and water related issues.
            Our team of professionals can tackle any task from routine maintenance to replacement.  
         ` },

    {image: "/images/serviceImages/Decks.jpg", 
    service:"DECKS & FENCES", 
    header: "Is it time to update your backyard?", 
    gallery: [
        '../images/photoGallery/decks&fences/dnf1.jpg', '../images/photoGallery/decks&fences/dnf2.jpg', '../images/photoGallery/decks&fences/dnf3.jpg', '../images/photoGallery/decks&fences/dnf4.jpg', 
        '../images/photoGallery/decks&fences/dnf5.jpg', '../images/photoGallery/decks&fences/dnf6.jpg', '../images/photoGallery/decks&fences/dnf7.jpg', '../images/photoGallery/decks&fences/dnf8.jpg', 
        '../images/photoGallery/decks&fences/dnf9.jpg', '../images/photoGallery/decks&fences/dnf10.jpg', '../images/photoGallery/decks&fences/dnf11.jpg', '../images/photoGallery/decks&fences/dnf12.jpg', 
        '../images/photoGallery/decks&fences/dnf13.jpg', '../images/photoGallery/decks&fences/dnf14.jpg', '../images/photoGallery/decks&fences/dnf15.jpg', '../images/photoGallery/decks&fences/dnf16.jpg', 
    ],
        paragraph: 
            `There is nothing more satisfying then the curling aroma of grilling short ribs as you entertain friends and family
            on your brand new custom built deck. The feeling is only paralleled by all the great compliments you've received
            on your masterfully built privacy fence that ties the whole backyard together.
            Now, if you're sitting at home wishing this was you, maybe it's time you gave us a call, and let us turn your dream into a reality. 
            ` },

    {image: "/images/serviceImages/stonework.jpg", 
    service:"STONEWORK & CONCRETE", 
    header: "Is it time to finally build that patio?",
    gallery: [
        '../images/photoGallery/stonework&concrete/snc1.jpg', '../images/photoGallery/stonework&concrete/snc2.jpg', '../images/photoGallery/stonework&concrete/snc3.jpg', '../images/photoGallery/stonework&concrete/snc4.jpg', 
        '../images/photoGallery/stonework&concrete/snc5.jpg', '../images/photoGallery/stonework&concrete/snc6.jpg', '../images/photoGallery/stonework&concrete/snc7.jpg', 
    ],  
        paragraph: 
            `Genesis Home Remodeling offers a wide array of masonry services from stone paving to building stone walls to everything in between. 
            We guide our clients from initial design and planning to installation and maintenance. 
            Our main focus is bringing your vision to reality.
            Our team of expert stone masons are committed to ensuring your satisfaction, and to providing exceptional service at an affordable price` },

    {image: "/images/serviceImages/otherServices.jpg", 
    service:"OTHER SERVICES", 
    header: "Gensis is your one stop shop for home remodeling", 
    gallery: [], 
        paragraph: 
            `All phases of construction from the ground up are provided under the capabilities
            and management of Genesis Home Remodeling. Quality sub contractors and employees
            contribute to some of these tasks, but always under the expert supervision of Genesis’
            management to insure that the job gets done correctly the first time, on time, and
            within budget.`}
];

export default class ServicePage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {

        }
        this.renderPhotoGallery = this.renderPhotoGallery.bind(this);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }

    //displays a photo gallery if images exist
    renderPhotoGallery(){
        if(serviceList[this.props.serviceIndex]['gallery'].length > 0)
        {
            return (
                <div id="service-page-gallery-container">
                    <h1>Project Gallery</h1>
                    <PhotoGallery gallery={serviceList[this.props.serviceIndex]['gallery']}/>
                </div>
            );
        }
    }

    render(){
        return(
            <div id="service-page-wrapper" class="my-col">
                <Navbar/>
                <ContactBanner/>
                <div className="header-spacer"/>
                <div id="service-page-container" class="my-row">
                    <div id="service-page-content-container" class="my-col" >

                        {/* header image */}
                        <div id="service-page-image-container" class="my-col">
                            <div id="service-page-image" class="my-col" data-aos="slide-right" data-aos-duration="1000" data-aos-delay="500" style={{"background-image" : `url(${serviceList[this.props.serviceIndex].image})`}}>
                                <h1>{serviceList[this.props.serviceIndex].service}</h1>
                            </div>
                        </div>

                        {/* content */}
                        <div id="service-page-text-container">
                            <h1>{serviceList[this.props.serviceIndex].header}</h1>
                            <p>
                                {serviceList[this.props.serviceIndex].paragraph}
                                <a href="/#contact-us">Contact us today to get a free estimate!</a>
                            </p>
                        </div>

                        {/* GALLERY */}
                        {this.renderPhotoGallery()}

                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}