import React, { Component } from 'react'
import '../Styling/QuoteBox.css'
import {CSSTransition, TransitionGroup} from "react-transition-group";

export default class QuoteBox extends Component 
{
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            <TransitionGroup id="quote-container" class="my-row">
                <CSSTransition  key={this.props._id} timeout={300} classNames="fade">
                    
                    <blockquote>
                        
                        <p>{this.props.quote}</p>
                        <div id="author-container">
                            <p id="authors-name">{this.props.author}</p>
                            <p id="authors-loc">{this.props.location}</p>
                        </div>
                        
                    </blockquote>

                </CSSTransition>
            </TransitionGroup>
        )
    }
}

