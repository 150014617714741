import React, {Component, lazy, Suspense} from 'react'
import Navbar from '../Components/Navbar'
import ContactBanner from '../Components/ContactBanner'
import Footer from '../Components/Footer'
import '../Styling/Gallery.css';
import Modal from 'react-modal';
import 'aos/dist/aos.css';


const MyPhotoGallery = lazy(() => import('../Components/MyPhotoGallery'));

Modal.setAppElement('#root');

// this array stores the path of all the gallery images
const galleryList = [
    "../images/photoGallery/drywall/drywall1.jpg", "../images/photoGallery/drywall/drywall2.jpg", "../images/photoGallery/drywall/drywall3.jpg", "../images/photoGallery/drywall/drywall4.jpg", 
    "../images/photoGallery/drywall/drywall5.jpg", "../images/photoGallery/drywall/drywall6.jpg", "../images/photoGallery/drywall/drywall7.jpg", "../images/photoGallery/drywall/drywall8.jpg", 
    "../images/photoGallery/drywall/drywall9.jpg", "../images/photoGallery/drywall/drywall10.jpg", "../images/photoGallery/drywall/drywall11.jpg", "../images/photoGallery/drywall/drywall12.jpg",
    "../images/photoGallery/flooring/flooring1.jpg", "../images/photoGallery/flooring/flooring2.jpg", "../images/photoGallery/flooring/flooring3.jpg", "../images/photoGallery/flooring/flooring4.jpg", "../images/photoGallery/flooring/flooring5.jpg", 
    "../images/photoGallery/flooring/flooring6.jpg", "../images/photoGallery/flooring/flooring7.jpg", "../images/photoGallery/flooring/flooring8.jpg", "../images/photoGallery/flooring/flooring9.jpg", "../images/photoGallery/flooring/flooring10.jpg", 
    "../images/photoGallery/flooring/flooring11.jpg", "../images/photoGallery/flooring/flooring12.jpg", "../images/photoGallery/flooring/flooring13.jpg", "../images/photoGallery/flooring/flooring14.jpg", "../images/photoGallery/flooring/flooring15.jpg", 
    "../images/photoGallery/flooring/flooring16.jpg", "../images/photoGallery/flooring/flooring17.jpg", "../images/photoGallery/flooring/flooring18.jpg", "../images/photoGallery/flooring/flooring19.jpg", "../images/photoGallery/flooring/flooring20.jpg", 
    "../images/photoGallery/flooring/flooring21.jpg", "../images/photoGallery/flooring/flooring22.jpg", "../images/photoGallery/flooring/flooring23.jpg", "../images/photoGallery/flooring/flooring24.jpg", "../images/photoGallery/flooring/flooring25.jpg", 
    "../images/photoGallery/kitchens/kitchenP1_1.jpg", "../images/photoGallery/kitchens/kitchenP1_2.jpg", "../images/photoGallery/kitchens/kitchenP2_1.jpg", "../images/photoGallery/kitchens/kitchenP2_2.jpg",
    "../images/photoGallery/kitchens/kitchenP3_1.jpg", "../images/photoGallery/kitchens/kitchenP3_2.jpg", "../images/photoGallery/kitchens/kitchenP4_1.jpg", "../images/photoGallery/kitchens/kitchenP4_2.jpg",
    "../images/photoGallery/kitchens/kitchenP4_3.jpg", "../images/photoGallery/kitchens/kitchenP5_1.jpg", "../images/photoGallery/kitchens/kitchenP6_1.jpg", "../images/photoGallery/kitchens/kitchenP7_1.jpg",
    "../images/photoGallery/kitchens/kitchenP7_2.jpg", "../images/photoGallery/kitchens/kitchenP7_3.jpg", "../images/photoGallery/kitchens/kitchenP8_1.jpg", "../images/photoGallery/kitchens/kitchenP8_2.jpg",
    "../images/photoGallery/kitchens/kitchenP9_1.jpg", "../images/photoGallery/bathrooms/bathroomJob1_1.jpg","../images/photoGallery/bathrooms/bathroomJob1_2.jpg","../images/photoGallery/bathrooms/bathroomJob1_3.jpg","../images/photoGallery/bathrooms/bathroomJob1_4.jpg",
    "../images/photoGallery/bathrooms/bathroomJob2_1.jpg","../images/photoGallery/bathrooms/bathroomJob2_2.jpg","../images/photoGallery/bathrooms/bathroomJob2_3.jpg","../images/photoGallery/bathrooms/bathroomJob4_1.jpg",
    "../images/photoGallery/bathrooms/bathroomJob4_2.jpg","../images/photoGallery/bathrooms/bathroomJob5_1.jpg","../images/photoGallery/bathrooms/bathroomJob5_2.jpg","../images/photoGallery/bathrooms/bathroomJob5_3.jpg",
   "../images/photoGallery/bathrooms/bathroomJob6_1.jpg","../images/photoGallery/bathrooms/bathroomJob6_2.jpg","../images/photoGallery/bathrooms/bathroomJob6_3.jpg","../images/photoGallery/bathrooms/bathroomJob7_1.jpg",
   "../images/photoGallery/bathrooms/bathroomJob7_2.jpg","../images/photoGallery/bathrooms/bathroomJob7_3.jpg","../images/photoGallery/bathrooms/bathroomJob8_1.jpg","../images/photoGallery/bathrooms/bathroomJob8_2.jpg",
    "../images/photoGallery/bathrooms/bathroomJob9_1.jpg","../images/photoGallery/bathrooms/bathroomJob9_2.jpg","../images/photoGallery/bathrooms/bathroomJob10_1.jpg","../images/photoGallery/bathrooms/bathroomJob10_2.jpg",
    "../images/photoGallery/bathrooms/bathroomJob10_3.jpg","../images/photoGallery/bathrooms/bathroomJob10_4.jpg","../images/photoGallery/bathrooms/bathroomJob11_1.jpg","../images/photoGallery/bathrooms/bathroomJob11_2.jpg",
   "../images/photoGallery/bathrooms/bathroomJob12_1.jpg","../images/photoGallery/bathrooms/bathroomJob12_2.jpg","../images/photoGallery/bathrooms/bathroomMisc2.jpg","../images/photoGallery/bathrooms/bathroomMisc4.jpg",
   '../images/photoGallery/windows&doors/wnd1.jpg', '../images/photoGallery/windows&doors/wnd2.jpg', '../images/photoGallery/roofing&gutters/roofing1.jpg', '../images/photoGallery/roofing&gutters/roofing2.jpg', '../images/photoGallery/roofing&gutters/roofing3.jpg', '../images/photoGallery/roofing&gutters/roofing4.jpg', 
   '../images/photoGallery/roofing&gutters/roofing5.jpg', '../images/photoGallery/roofing&gutters/roofing6.jpg', '../images/photoGallery/roofing&gutters/roofing7.jpg', '../images/photoGallery/roofing&gutters/roofing8.jpg', 
   '../images/photoGallery/roofing&gutters/roofing9.jpg', '../images/photoGallery/roofing&gutters/roofing10.jpg', '../images/photoGallery/roofing&gutters/roofing11.jpg', 
    '../images/photoGallery/decks&fences/dnf1.jpg', '../images/photoGallery/decks&fences/dnf2.jpg', '../images/photoGallery/decks&fences/dnf3.jpg', '../images/photoGallery/decks&fences/dnf4.jpg', 
    '../images/photoGallery/decks&fences/dnf5.jpg', '../images/photoGallery/decks&fences/dnf6.jpg', '../images/photoGallery/decks&fences/dnf7.jpg', '../images/photoGallery/decks&fences/dnf8.jpg', 
   '../images/photoGallery/decks&fences/dnf9.jpg', '../images/photoGallery/decks&fences/dnf10.jpg', '../images/photoGallery/decks&fences/dnf11.jpg', '../images/photoGallery/decks&fences/dnf12.jpg', 
   '../images/photoGallery/decks&fences/dnf13.jpg', '../images/photoGallery/decks&fences/dnf14.jpg', '../images/photoGallery/decks&fences/dnf15.jpg', '../images/photoGallery/decks&fences/dnf16.jpg', 
   '../images/photoGallery/stonework&concrete/snc1.jpg', '../images/photoGallery/stonework&concrete/snc2.jpg', '../images/photoGallery/stonework&concrete/snc3.jpg', '../images/photoGallery/stonework&concrete/snc4.jpg', 
   '../images/photoGallery/stonework&concrete/snc5.jpg', '../images/photoGallery/stonework&concrete/snc6.jpg', '../images/photoGallery/stonework&concrete/snc7.jpg', 
];

export default class Gallery extends Component 
{
    constructor(props){
        super(props);

        this.state={
            modalOn: false,
            imageUrl: "",
            imageIndex: 0
        }

        this.handleModal = this.handleModal.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);
        this.handleLeftClick = this.handleLeftClick.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0,0);
    }

    handleModal(){
        this.setState(state => ({
            modalOn: !this.state.modalOn
        }));
    }
    handleImage(imgIndex){

        this.setState(() => ({
            imageUrl: galleryList[imgIndex],
            imageIndex: imgIndex
        }));
    }
    handleLeftClick(){
        let nextIndex = this.state.imageIndex - 1;
        if(nextIndex < 0)
        {
            nextIndex = galleryList.length -1;
        }
        this.setState(() => ({
            imageIndex: nextIndex,
            imageUrl: galleryList[nextIndex]
        }));
    }
    handleRightClick(){
        let nextIndex = this.state.imageIndex +1;
        if(nextIndex >= galleryList.length){
            nextIndex = 0;
        }
        this.setState(() => ({
            imageIndex: nextIndex,
            imageUrl: galleryList[nextIndex]
        }));

    }
    render(){
        return (
            <div id="gallery-page-container" class="my-col">
                {/* MODAL STUFF */}
                <Modal 
                    isOpen={this.state.modalOn} //toggles whether or not to display modal 
                    //modal window styling
                    style=
                    {{
                        content:{
                            backgroundColor: 'rgb(0,0,0)', 
                            position: "absolute",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            width: "90vw", 
                            height: "90vh",
                            left: "5vw",
                            bottom: "5vw",
                            padding: "0%",
                        }, 
                        overlay:{
                            zIndex:5, backgroundColor:'rgba(0, 0, 0, 0.75)'}
                    }}
                >

                    <div id="modal-content-container" class="my-row">
                        {/* MODAL BUTTONS */}
                        <button id="modal-exit-button" class="my-row" onClick={this.handleModal}><i className="far fa-window-close"></i></button>
                        <button id="modal-left-arrow" onClick={this.handleLeftClick}> <i class="fas fa-angle-left"></i> </button>
                        <button id="modal-right-arrow" onClick={this.handleRightClick}> <i class="fas fa-angle-right"></i>  </button>
                        {/* MODAL CONTENT */}
                        <p id="image-position-ratio">{`${this.state.imageIndex + 1} / ${galleryList.length}`}</p>
                        <div id="modal-image-container" style={{backgroundImage:'url('+ this.state.imageUrl.substring(2, this.state.imageUrl.length)}}/>
                    </div>

                </Modal>
                {/* MODAL ENDS */}
                
                {/* GALLERY PAGE CONTENT STARTS HERE */}
                <div id="gallery-page-content" class="my-col">
                    <Navbar/>
                    <ContactBanner/>
                    <div className="header-spacer"/>

                    {/* HEADER IMAGE */}
                    <div id="gallery-header-container" class="my-col" data-aos="slide-right">
                        <div id="gallery-header-image" class="my-col" style={{"background-image" : `url("/images/serviceImages/otherServices.jpg")`}}>
                            <h1>Project Gallery</h1>
                        </div>
                    </div>

                    {/* PHOTO GALLERY */}
                    <div id="my-photo-gallery-wrapper" class="my-row">
                        <Suspense fallback={<div>Loading...</div>}>
                            <MyPhotoGallery imageList={galleryList} modalClicked={this.handleModal} imgClicked={this.handleImage}/>
                        </Suspense>
                    </div>

                    <Footer/>
                </div>

            </div>
           
        )
    }
}

