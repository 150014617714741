import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import './App.css';
import ServicePage from './Pages/ServicePage'
import Home from './Pages/Home'
import Gallery from './Pages/Gallery'

const serviceList = [
  {image: "/images/drywall.jpg", service: "Drywall"},
  {image: "/images/painting.jpg", service: "Painting" },
  {image: "/images/flooring.png", service:"Flooring"},
  {image: "/images/kitchen.jpg", service: "Kitchens"},
  {image: "/images/navyBathroom2.jpg", service:"Bathrooms"},
  {image: "/images/windows.jpg", service:"Windows&Doors"},
  {image: "/images/roofing.png", service:"Roofing,Gutters,&Downspouts"},
  {image: "/images/Decks.jpg", service:"Decks&Fences"},
  {image: "/images/stonework.jpg", service:"Stonework&Concrete"},
  {image: "/images/otherServices.jpg", service:"OtherServices"}
];

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/project-gallery" exact component={Gallery}/>
        {serviceList.map((value, index) => {
          return(
            <Route path={`/service-page/${value.service}`} exact render={() => <ServicePage serviceIndex={index}/>}/>
          )
        })};
      </Switch>
    </Router>
  );
}

export default App;
