import React, {useEffect} from 'react'
import '../Styling/About.css'
import "aos/dist/aos.css";
import Aos from 'aos';

function About() {
    // Initializes Animations on Scroll (AOS)
    useEffect(() => {
        Aos.init()
    }, []);
    return (
        <div id="about-container" class="my-col">
            {/* div navbar points to*/}
            <div id="about-us" class="hidden-spacer"/>
            {/* about us content layout container */}
            <div id="about-us-container" class="my-row">
                <div data-aos="slide-right"  data-aos-duration="1000" id="about-text-container" class="my-col">
                    <h1>
                        About Us
                    </h1>
                    <p>
                        Genesis Home Remodeling is a Virginia-based full-service general contractor
                        that provides quality work, dependable service, and competitive prices throughout
                        the state of Virginia, Maryland, and the District of Columbia.
                    </p>
                    <p>
                        Genesis Home Remodeling is owned and managed by Ivan Ruano, who has over 30
                        years of experience in the construction business. Genesis covers all phases of
                        construction including, but not limited to: new construction, renovation, restoration,
                        and general residential and commercial building remodeling. Genesis is well established
                        with an outstanding bond capacity, and with an excellent safety record, which has been
                        maintained through diligent adherence to safety practices and company policies.
                    </p>
                    <p>
                        Genesis is dedicated to professional integrity and to excellence in the performance
                        of all contracts. Genesis is proud of its reputation as a small general contractor that
                        produces big results for its customers.
                    </p>
                </div>
                <div id="about-img-container" class="my-col">
                    {/* This header only appears in mobile */}
                    <h1 data-aos="slide-right" data-aos-duration="1000">About Us</h1>
                    <img data-aos="zoom-in" data-aos-duration="1000" src="../images/aboutImages/happyWorkers.jpg" alt="about pix"/>
                </div>
            </div>
        </div>
    )
}

export default About
